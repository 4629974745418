export const formatLargeNumber = (
  numStr?: number | string,
  showDollarSign: boolean = true
): string => {
  if (!numStr || isNaN(Number(numStr))) return "-";

  const num = Number(numStr);
  const dollarSign = showDollarSign ? "$ " : "";
  if (num >= 1_000_000_000) {
    // Billions
    return `${dollarSign}${(num / 1_000_000_000).toFixed(2)}B`;
  } else if (num >= 1_000_000) {
    // Millions
    return `${dollarSign}${(num / 1_000_000).toFixed(2)}M`;
  } else {
    // Less than a million, return the original number
    return `${dollarSign}${num.toFixed(2)}`;
  }
};

export const formatWithCommas = (numStr?: number | string): string => {
  if (!numStr || isNaN(Number(numStr))) return "-";
  const num = Number(numStr);

  return num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const flexibleNumberFormat = (
  numStr?: number | string,
  isPercent: boolean = false
): string => {
  if (!numStr || isNaN(Number(numStr))) return "-";
  const num = Number(numStr);

  // Very small numbers (scientific notation, close to 0)
  if (num > 0 && num < 0.0001) {
    return num.toFixed(8).replace(/\.?0+$/, ""); // Keep 8 decimal places but remove trailing zeros
  }

  // Small numbers (0 < num < 1)
  if (num >= 0.0001 && num < 1) {
    return num.toFixed(3).replace(/\.?0+$/, ""); // Keep 3 decimal places but remove trailing zeros
  }

  if (num >= 1 && num < 10) {
    return num.toFixed(3).replace(/\.?0+$/, ""); // Keep 3 decimal places but remove trailing zeros
  }

  // Medium numbers (num >= 1)
  if (num >= 10 && num < 1000) {
    return num.toFixed(2).replace(/\.?0+$/, ""); // Keep 2 decimal places but remove trailing zeros
  }

  // Larger numbers (num >= 1000)
  if (isPercent) {
    // No commas for percentages or exact number formatting
    return num.toFixed(2).replace(/\.?0+$/, "");
  }

  // Larger numbers (num >= 1000)
  return num.toLocaleString("en-US", { maximumFractionDigits: 2 }); // Format with commas and 2 decimal places
};
