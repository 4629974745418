"use client";
import Image from "next/image";
import { MixerHorizontalIcon, TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { CommonMarketType } from "@type/types";
import Table, { TableColumn } from "../table/table";
import PairTitledBadge from "../badge/pair.titled.badge";
import { useEffect, useState } from "react";
import { setImagePath } from "@utils/transformer";
import SingleTitledBadge from "../badge/single.titled.badge";
import { flexibleNumberFormat } from "@utils/number.transformer";
import { capitalizeFirstLetter } from "@utils/tools";
import ChangeablePriceLabel from "../badge/changeable.price.label";
import { fetchService } from "@service/service";
import ChangeablePercentageLabel from "../badge/changeable.percent.label";

export default function MarketBoard({
  tables,
}: {
  tables: {
    label: string;
    data: CommonMarketType[];
  }[];
}) {
  const [selectedTable, setSelectedTable] = useState<{
    label: string;
    data: CommonMarketType[];
  }>(tables[0]);

  const Columns: TableColumn<CommonMarketType>[] = [
    {
      key: "id",
      label: "Name",
      render: (data: CommonMarketType) => {
        if (!data) return <>-</>;
        const { symbol } = data;
        if (data?.forexMarket) {
          const { forexMarket } = data;
          return (
            <div className="flex flex-row items-center justify-start gap-2">
              <PairTitledBadge
                baseMarketIcon={setImagePath(forexMarket.baseLogo)}
                quoteMarketIcon={setImagePath(forexMarket.quoteLogo)}
                symbol={symbol}
                imageSize={16}
                marketType={data?.marketType}
              />
            </div>
          );
        }
        if (data?.stockMarket) {
          const { stockMarket } = data;
          return (
            <div className="flex flex-row items-center justify-start gap-2">
              <SingleTitledBadge
                icon={setImagePath(stockMarket.logo)}
                symbol={symbol}
                imageSize={16}
                marketType={data?.marketType}
              />
            </div>
          );
        }
        if (data?.cryptoSymbol) {
          const { cryptoSymbol } = data;
          return (
            <div className="flex flex-row items-center justify-start gap-2">
              <SingleTitledBadge
                icon={setImagePath(cryptoSymbol.logo)}
                symbol={symbol}
                imageSize={16}
                marketType={data?.marketType}
              />
            </div>
          );
        }

        return <>-</>;
      },
    },
    {
      key: "price",
      label: "Price",
      render: (data: CommonMarketType) => (
        <ChangeablePriceLabel
          symbol={data?.symbol}
          price={data?.price}
          marketType={data?.marketType}
        />
      ),
    },
    {
      key: "change",
      label: "Change",
      render: (data: CommonMarketType) => {
        return (
          <ChangeablePercentageLabel
            symbol={data?.symbol}
            percentChange={data?.percentChange}
            marketType={data?.marketType}
          />
        );
      },
    },
  ];

  // const fetchMarket = async (label: string) => {
  //   const data = await fetchService<CommonMarketType[]>({
  //     url: `element/public/home/${label}`,
  //     method: "GET",
  //     responseType: "json",
  //   });
  //   // console.log(data);
  // };

  // useEffect(() => {
  //   console.log(selectedTable);

  //   // fetchMarket(selectedTable.label);
  //   return () => {};
  // }, [selectedTable]);

  return (
    <div
      className={clsx(
        "flex flex-col border border-color-light border-solid rounded-md p-4 mx-5 w-[100%] self-center",
        // responsive
        "sm:flex-1"
      )}
    >
      <div className={clsx("flex items-center justify-start  mb-0 gap-4", "sm:mb-6")}>
        <Image src={"/svg/icon/bar-chart.svg"} alt={`market icon`} width={18} height={18} />
        <span className="text-md font-bold">Market</span>
      </div>

      <div
        className={clsx(
          "flex flex-row items-center justify-between mb-6 gap-7 border-b border-color-light border-solid p-4 pb-2"
        )}
      >
        <div className={clsx("flex flex-row items-center justify-start gap-4 self-start")}>
          {tables.map((table) => (
            <button
              onClick={() => setSelectedTable(table)}
              className={clsx(
                "text-xs py-1 px-3",
                table.label === selectedTable.label ? "bg-color-primary rounded" : ""
              )}
              key={table.label}
            >
              <span
                className={clsx(
                  "text-xs",
                  table.label === selectedTable.label ? "text-color-primary" : ""
                )}
              >
                {capitalizeFirstLetter(table.label)}
              </span>
            </button>
          ))}
        </div>
        <button className={clsx("flex self-end flex-row gap-2 py-1 px-2 bg-color-light rounded")}>
          <MixerHorizontalIcon width={14} height={14} />
          <span className={clsx("text-xs hidden", "sm:flex")}>Filters</span>
        </button>
      </div>

      <Table data={{ tableData: selectedTable.data, columnData: Columns }} />
    </div>
  );
}
