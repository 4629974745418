import Image from "next/image";

interface PairBadgeProps {
  imageSize?: number;
  baseMarketIcon: string;
  quoteMarketIcon: string;
}

export default function PairBadge({
  imageSize = 20,
  baseMarketIcon,
  quoteMarketIcon,
}: PairBadgeProps) {
  return (
    <div className="relative flex mb-2 ">
      <Image src={baseMarketIcon} alt={`icon`} width={imageSize} height={imageSize} />
      <Image
        className="absolute top-5px right-5px z-10 border border-solid border-gray-300 rounded-full"
        src={quoteMarketIcon}
        alt={`flag`}
        width={imageSize}
        height={imageSize}
      />
    </div>
  );
}
