"use client";

import { SocketBroadcastType, SocketDataType, SocketMarketPriceDataType } from "@type/types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

// Define the context with Socket type or null
const SocketContext = createContext<{ data: SocketDataType | null }>({
  data: null,
});

// Hook to use the SocketContext in components
export const useSocket = () => {
  return useContext(SocketContext);
};

// Provider component to handle WebSocket connection
export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [data, setData] = useState<SocketDataType | null>(null);

  useEffect(() => {
    // Connect to Socket.IO server
    const socketIo = io(process.env.NEXT_PUBLIC_SOCKET_URL as string); // Change URL to your server

    // setSocket(socketIo);

    socketIo.on("price_update", (data: SocketMarketPriceDataType) => {
      setData((prev) => ({
        ...prev,
        [SocketBroadcastType.MARKET]: {
          ...prev?.[SocketBroadcastType.MARKET],
          [data.marketType]: { data },
        },
      }));
    });
    // Clean up the connection when the component unmounts
    return () => {
      socketIo.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={{ data }}>{children}</SocketContext.Provider>;
};

// "use client";
// import React, { useState, useEffect, useRef, createContext, ReactNode } from "react";
// import { io, Socket } from "socket.io-client";

// // Define the shape of the context value
// export interface SocketContextType {
//   message?: Record<string, unknown>;
// }

// // Create a context with the appropriate type
// const SocketContext = createContext<SocketContextType | undefined>(undefined);

// interface SocketProviderProps {
//   children: ReactNode;
// }

// export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
//   const socket = useRef<Socket | null>(null); // Define the socket with the correct type
//   const [message, setMessage] = useState<Record<string, unknown> | undefined>();

//   useEffect(() => {
//     socket.current = io(process.env.NEXT_PUBLIC__SOCKET);

//     socket.current.on("price_update", (data: any) => {
//       if (data) {
//         setMessage(data);
//       }
//       // Set the incoming message
//     });

//     return () => {
//       if (socket.current) {
//         socket.current.disconnect(); // Disconnect the socket on cleanup
//       }
//     };
//   }, []);

//   const contextValue: SocketContextType = {
//     message,
//   };

//   return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
// };

// export { SocketContext };
