import React from "react";

export type TableColumn<T> = {
  key?: string;
  label: string;
  render: (data: T, index: number) => React.ReactNode;
};

export type TableDataType<T> = {
  columnData: TableColumn<T>[];
  tableData: T[];
};

interface TableDataProps<T> {
  data: TableDataType<T>;
}

export default function Table<T extends Record<string, any>>({ data }: TableDataProps<T>) {
  const { columnData, tableData } = data;

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr className="border-color-light  ">
            {columnData?.map((item) => (
              <th key={String(item.key || item.label)}>{item.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, rowIndex) => (
            <tr key={rowIndex} className="border-color-light ">
              {columnData.map((column, colIndex) => (
                <td key={colIndex} className="w-[40px] max-w-[40px] min-h-12 h-12 p-3">
                  {column.render(rowData, rowIndex)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
