"use client";
import { MarketType, SocketBroadcastType } from "@type/types";
import { flexibleNumberFormat } from "@utils/number.transformer";
import { useSocket } from "src/provider/socket.provider";

interface ChangeablePriceLabelProps {
  symbol: string;
  price?: string | number;
  marketType: MarketType;
}

export default function ChangeablePriceLabel({
  symbol,
  price,
  marketType,
}: ChangeablePriceLabelProps) {
  const { data: socketData } = useSocket();
  if (!price || !marketType) {
    return null;
  }

  const updatedPrice =
    socketData?.[SocketBroadcastType.MARKET]?.[marketType]?.data?.updatedValues?.[symbol]?.price ||
    price;
  return (
    <p className="text-xs">{`${flexibleNumberFormat(updatedPrice)} ${
      marketType === MarketType.FOREX ? "" : "$"
    }`}</p>
  );
}
