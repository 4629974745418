import Image from "next/image";
import PairBadge from "./pair.badge";
import { MarketType } from "@type/types";
import Link from "next/link";
import { lightLogoPath } from "@config/constants";

interface PairTitledBadgeProps {
  imageSize?: number;
  baseMarketIcon?: string;
  quoteMarketIcon?: string;
  symbol?: string;
  marketType?: MarketType;
}

export default function PairTitledBadge({
  imageSize = 20,
  baseMarketIcon,
  quoteMarketIcon,
  symbol,
  marketType,
}: PairTitledBadgeProps) {
  if (!symbol || !baseMarketIcon || !quoteMarketIcon) {
    return null;
  }

  if (marketType) {
    return (
      <Link
        href={`/${marketType.toLowerCase()}/${symbol.split("/").join("-").toLowerCase()}`}
        className="flex items-center gap-2"
      >
        <PairBadge
          baseMarketIcon={baseMarketIcon || lightLogoPath}
          quoteMarketIcon={quoteMarketIcon || lightLogoPath}
          imageSize={imageSize}
        />
        <span className="text-xs leading-[15px]">{`${symbol}`}</span>
      </Link>
    );
  }
  return (
    <div className="flex items-center gap-2">
      <PairBadge
        baseMarketIcon={baseMarketIcon || lightLogoPath}
        quoteMarketIcon={quoteMarketIcon || lightLogoPath}
        imageSize={imageSize}
      />
      <span className="text-xs leading-[15px]">{`${symbol}`}</span>
    </div>
  );
}
