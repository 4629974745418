"use client";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import { MarketType, SocketBroadcastType } from "@type/types";
import { flexibleNumberFormat } from "@utils/number.transformer";
import clsx from "clsx";
import { useSocket } from "src/provider/socket.provider";

interface ChangeablePercentageLabelProps {
  symbol: string;
  percentChange?: number | string;
  marketType: MarketType;
}

export default function ChangeablePercentageLabel({
  symbol,
  percentChange,
  marketType,
}: ChangeablePercentageLabelProps) {
  const { data: socketData } = useSocket();
  if (!percentChange || !marketType) {
    return null;
  }

  const updatedChange =
    Number(
      socketData?.[SocketBroadcastType.MARKET]?.[marketType]?.data?.updatedValues?.[symbol]
        ?.percentChange || percentChange
    ) || 0;
  return (
    <div className="flex  flex-row items-center justify-start">
      {updatedChange === 0 ? null : updatedChange > 0 ? (
        <TriangleUpIcon className="text-green-500" />
      ) : (
        <TriangleDownIcon className="text-red-500" />
      )}
      <span
        className={clsx(
          "text-xs",
          updatedChange === 0 ? "" : updatedChange > 0 ? "text-green-500" : "text-red-500"
        )}
      >
        {flexibleNumberFormat(updatedChange, true)}%
      </span>
    </div>
  );
}
